import React, { useContext, useEffect, useRef, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as CaretSVG } from '../../../images/caret_down.svg';
import { BusinessData } from '../../../types/businessRegistry';
import { isBrowser } from '../../../utils/browser';
import { generateKey } from '../../../utils/keys';
import './constructionInfo.scss';

const ConstructionInfo: React.FC = () => {
  const { i18n, instance } = useContext(GlobalContext);
  const isMobile = isBrowser && window.innerWidth >= 992 ? false : true;

  const business = instance as BusinessData;

  const {
    generalContractorCategories,
    rbqInfo,
    specializedContractorCategories,
  } = business.attributes;

  const [generalToggle, useGeneralToggle] = useState(false);
  const [specializedToggle, useSpecializedToggle] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reRender, useRerender] = useState(false); // keep even if reRender is not used, useRerender is needed to force rerender
  let generalClosedHeight = 0;
  let specializedClosedHeight = 0;
  let isGeneralSeeMoreHidden = false;
  let isSpecializedSeeMoreHidden = false;

  if (
    !rbqInfo?.rbq &&
    (typeof generalContractorCategories === 'undefined' ||
      generalContractorCategories.data.length === 0) &&
    (typeof specializedContractorCategories === 'undefined' ||
      specializedContractorCategories.data.length === 0)
  )
    return null;

  const generalElRefs = useRef<HTMLSpanElement[]>([]);
  const specializedElRefs = useRef<HTMLSpanElement[]>([]);

  useEffect(() => {
    generalElRefs.current = generalElRefs.current.slice(
      0,
      generalContractorCategories.data.length
    );
    specializedElRefs.current = specializedElRefs.current.slice(
      0,
      specializedContractorCategories.data.length
    );

    useRerender(true);
  }, [business.attributes]);

  generalElRefs.current.map((element, i) => {
    if (i <= 1) {
      generalClosedHeight += element.offsetHeight;
    } else isGeneralSeeMoreHidden = true;
  });

  specializedElRefs.current.map((element, i) => {
    if (i <= 1) {
      specializedClosedHeight += element.offsetHeight;
    } else isSpecializedSeeMoreHidden = true;
  });

  return (
    <div className="construction-stats">
      {rbqInfo?.rbq && (
        <div className="construction-rbq">
          <div className="construction-label">
            {i18n?.t('business_page.rbq')}
          </div>
          <div className="construction-text">{rbqInfo.rbq}</div>
        </div>
      )}
      {typeof generalContractorCategories !== 'undefined' &&
        generalContractorCategories.data.length > 0 && (
          <div className="general-sector">
            <div className="construction-label">
              {i18n?.t('business_page.general_construction_sector')}
            </div>
            <div
              className="construction-chips"
              style={
                isMobile && !generalToggle
                  ? { height: `${generalClosedHeight + 9}px` }
                  : { height: '100%' }
              }
            >
              {generalContractorCategories.data.map((category, index) => {
                return (
                  <span
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ref={(el: any) => (generalElRefs.current[index] = el)}
                    className="construction-chip"
                    key={generateKey(`general_contractor_category_${index}`)}
                  >
                    {category.attributes.name}
                  </span>
                );
              })}
            </div>
            {isGeneralSeeMoreHidden && (
              <span
                className="see-more"
                onClick={() => useGeneralToggle(!generalToggle)}
              >
                {i18n?.t('see_more')}
                <CaretSVG
                  style={generalToggle ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
            )}
          </div>
        )}
      {typeof specializedContractorCategories !== 'undefined' &&
        specializedContractorCategories.data.length > 0 && (
          <div className="specialized-sector">
            <div className="construction-label">
              {i18n?.t('business_page.specialized_construction_sector')}
            </div>
            <div
              className="construction-chips"
              style={
                isMobile && !specializedToggle
                  ? { height: `${specializedClosedHeight + 9}px` }
                  : { height: '100%' }
              }
            >
              {specializedContractorCategories.data.map((category, index) => {
                return (
                  <span
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ref={(el: any) => (specializedElRefs.current[index] = el)}
                    className="construction-chip"
                    key={generateKey(
                      `specialized_contractor_category_${index}`
                    )}
                  >
                    {category.attributes.name}
                  </span>
                );
              })}
            </div>

            {isSpecializedSeeMoreHidden && (
              <span
                className="see-more"
                onClick={() => useSpecializedToggle(!specializedToggle)}
              >
                {i18n?.t('see_more')}
                <CaretSVG
                  style={
                    specializedToggle ? { transform: 'rotate(180deg)' } : {}
                  }
                />
              </span>
            )}
          </div>
        )}
    </div>
  );
};

export default ConstructionInfo;
