import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { BusinessData } from '../../../types/businessRegistry';
import { generateKey } from '../../../utils/keys';
import CreeCertified from '../../global/creeCertified/creeCertified';
import Lead, { leadOptions } from '../../global/lead/lead';
import './aboutBusiness.scss';

const AboutBusiness: React.FC = () => {
  const { i18n, instance } = useContext(GlobalContext);

  const business = instance as BusinessData;

  const {
    creeCertified,
    naicsCategories,
    publicFacingDescription,
    publicFacingLegalBusinessName,
    schedule,
    headOfficeAddress,
  } = business.attributes;

  const googleMapsUrl = `https://www.google.com/maps/search/${
    [
      headOfficeAddress?.address,
      headOfficeAddress?.city,
      headOfficeAddress?.province?.data?.attributes.name,
      headOfficeAddress?.postalCode,  
    ]
    .join(',').replaceAll(' ', '+')}`

  const isHeadOfficeAddressComplete =
    headOfficeAddress &&
    headOfficeAddress?.address &&
    headOfficeAddress?.city &&
    headOfficeAddress?.province?.data?.attributes.name &&
    headOfficeAddress?.postalCode;

  return (
    <Row className="about-business">
      <Col lg={8} className="description">
        {creeCertified && <CreeCertified />}
        <h3>{publicFacingLegalBusinessName}</h3>
        <p>{publicFacingDescription}</p>
        <div>
          {naicsCategories.data.map((item, i) => {
            return (
              <span key={generateKey(`service_${i}`)} className="service">
                {item.attributes.name}
              </span>
            );
          })}
        </div>
      </Col>
      <div className="mobile-separator" />

      <Col xs={12} lg={4} className="address-info align-items-start">
        {isHeadOfficeAddressComplete && (
          <div className="address">
            <div>
              <div>{`${headOfficeAddress.address}`}</div>
              <div>{`${headOfficeAddress.city}, ${headOfficeAddress.province?.data.attributes.name}, ${headOfficeAddress.postalCode}`}</div>
            </div>
            <Lead
              url={googleMapsUrl}
              target="_blank"
              style={leadOptions.arrow}
            >
              {i18n?.t('business_page.directions')}
            </Lead>
          </div>
        )}
        {schedule.length > 0 && (
          <div className="schedule">
            {schedule.map((item, i) => (
              <div className="period" key={generateKey(`schedule_row_${i}`)}>
                <span className="days">{item.days}: </span>
                <span>{item.hours}</span>
              </div>
            ))}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default AboutBusiness;
