import axios from 'axios';
import React, { useEffect, useState } from 'react';

import BusinessInfo from '../components/business/businessInfo/businessInfo';
import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import Loading from '../components/global/loading/loading';
import { PageBase } from '../context/global-context';
import NotFoundPage from '../pages/404';
import { BusinessData } from '../types/businessRegistry';
import { PostType } from '../types/general';
import { PageContext } from '../types/page';
import { LocaleEnum, useI18n } from '../utils/i18n';

type BusinessContext = PageContext & {
  locale: LocaleEnum;
};

const BusinessPage: React.FC<{
  slug: string;
  pageContext: BusinessContext;
}> = ({ slug, pageContext }) => {
  const { locale, translations } = pageContext;
  const [instance, setInstance] = useState<BusinessData>();
  const [loading, setLoading] = useState(true);

  const i18n = useI18n(translations);

  useEffect(() => {
    try {
      // Use collection instead of single endpoint
      // single endpoint return draft data and use id, can't filter to get current local and slug
      // Can't use populate=* to get all 1st level relation with nested relation, so have to specify 1st level relation
      axios
        .get(
          `${process.env.GATSBY_STRAPI_URL}/api/businesses` +
            `?locale=${locale}` +
            `&filters[slug][$eq]=${slug}` +
            `&populate=basedInCommunity,canOperateInCommunities,coverPicture,generalContractorCategories,legalStructure,logo,` +
            `naicsCategories,owners,pictures.picture,schedule,seasonsOfOperation,specializedContractorCategories,localizations,` +
            `headOfficeAddress.province,rbqInfo.respondentAddress.province`
        )
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.data && responseData.data.length === 1) {
              const instance: BusinessData = {
                postType: PostType.business,
                ...responseData.data[0],
              };

              setInstance(instance);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  if (!loading && !instance) {
    return <NotFoundPage pageContext={pageContext} />;
  }

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          pageType={pageType.item}
          bannerType="business-info"
          title={i18n.t('business_page.banner.title')}
          crumbs={{
            previousPageTitle: i18n.t('business_registry.title'),
            previousPageLink: i18n.t('business_registry.slug'),
            crumbTitle: i18n.t('business_page.crumbs.title'),
          }}
        />
        {loading && (
          <div style={{ padding: '24px 0px' }}>
            <Loading size="large" />
          </div>
        )}
        {!loading && instance && <BusinessInfo />}
      </Layout>
    </PageBase>
  );
};

export default BusinessPage;
