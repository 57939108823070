import React, { useContext, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as CaretSVG } from '../../../images/caret_down.svg';
import { ReactComponent as FacebookSVG } from '../../../images/facebook.svg';
import { ReactComponent as InstagramSVG } from '../../../images/instagram.svg';
import { ReactComponent as LinkedinSVG } from '../../../images/linkedIn.svg';
import { ReactComponent as MailSVG } from '../../../images/mail.svg';
import { ReactComponent as PhoneSVG } from '../../../images/phone.svg';
import { ReactComponent as WebsiteSVG } from '../../../images/website.svg';
import { BusinessData } from '../../../types/businessRegistry';
import { phoneLink } from '../../../utils/formatStrings';
import { getUploadUrl } from '../../../utils/general';
import './businessBanner.scss';

const BusinessBanner: React.FC = () => {
  const [toggle, useToggle] = useState(false);

  const { i18n, instance } = useContext(GlobalContext);

  const business = instance as BusinessData;

  const {
    coverPicture,
    email,
    facebook,
    instagram,
    linkedin,
    logo,
    phone,
    website,
  } = business.attributes;

  const attributesArray = [];
  attributesArray.push(email, facebook, instagram, linkedin, phone, website);
  const filteredAttributesArray = attributesArray.filter((item) => {
    if (item) {
      return item;
    }
  });

  return (
    <>
      {coverPicture.data && (
        <figure
          className="business-banner-image"
          style={{
            backgroundImage: `url(${getUploadUrl(
              coverPicture.data.attributes.url
            )})`,
          }}
        ></figure>
      )}

      <div className="business-social-container">
        <div className="logo-col">
          {logo.data && (
            <figure className="business-logo-image">
              <img
                alt={logo.data.attributes.alternativeText}
                src={getUploadUrl(logo.data.attributes.url)}
              />
            </figure>
          )}
        </div>
        <div className="business-social-list-container clearfix">
          <ul
            className={`business-social-list
             ${
               !toggle
                 ? filteredAttributesArray.length == 1
                   ? 'close-1'
                   : 'close-2'
                 : 'open'
             }
            `}
          >
            <li>
              <PhoneSVG className="icon" />
              <a href={phoneLink(phone)}>{phone}</a>
            </li>
            {website && (
              <li>
                <WebsiteSVG className="icon" fill="" />
                <a
                  href={
                    website && !/^https?:\/\//i.test(website)
                      ? `http://${website}`
                      : website
                  }
                  target={'_blank'}
                >
                  {i18n?.t('see_website')}
                </a>
              </li>
            )}
            {email && (
              <li>
                <MailSVG className="icon" />
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            )}
            {linkedin && (
              <li>
                <LinkedinSVG className="icon" />
                <a href={linkedin} target={'_blank'}>
                  LinkedIn
                </a>
              </li>
            )}
            {facebook && (
              <li>
                <FacebookSVG className="icon" />
                <a href={facebook} target={'_blank'}>
                  Facebook
                </a>
              </li>
            )}
            {instagram && (
              <li>
                <InstagramSVG className="icon" />
                <a href={instagram} target={'_blank'}>
                  Instagram
                </a>
              </li>
            )}
          </ul>
          {filteredAttributesArray.length > 2 ? (
            <span className="see-more" onClick={() => useToggle(!toggle)}>
              {i18n?.t('see_more')}
              <CaretSVG style={toggle ? { transform: 'rotate(180deg)' } : {}} />
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default BusinessBanner;
