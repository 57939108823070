import React, { useContext } from 'react';

import Register from '../../homepage/register/register';
import AboutBusiness from '../aboutBusiness/aboutBusiness';
import BusinessBanner from '../businessBanner/businessBanner';
import BusinessImages from '../businessImages/businessImages';
import BusinessStats from '../businessStats/businessStats';
import ConstructionInfo from '../constructionInfo/constructionInfo';
import GlobalContext from '../../../context/global-context';
import { BusinessData } from '../../../types/businessRegistry';

import './businessInfo.scss';

const BusinessInfo: React.FC = () => {
  const { instance } = useContext(GlobalContext);

  const business = instance as BusinessData;

  let { pictures } = business.attributes;

  // Take only picture with data
  pictures = pictures.filter((item) => {
    return item.picture?.data;
  });

  return (
    <>
      <div className="business-info-container">
        <BusinessBanner />
        <AboutBusiness />
        <div className="separator" />
        <BusinessStats />
        {pictures.length > 0 ? (
          <BusinessImages pictures={pictures} />
        ) : (
          <div className="separator" />
        )}
        <ConstructionInfo />
      </div>
      <div className="register-section">
        <Register />
      </div>
    </>
  );
};

export default BusinessInfo;
