import React, { useContext } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { BusinessData } from '../../../types/businessRegistry';
import { getUploadUrl } from '../../../utils/general';
import { generateKey } from '../../../utils/keys';
import { ImageAttributes } from '../../../types/general';
import './businessImages.scss';

const BusinessImages: React.FC<{
  pictures: Array<{
    picture: {
      data: {
        attributes: ImageAttributes;
      };
    };
    caption: string;
  }>;
}> = ({ pictures }) => {
  if (pictures.length == 0) return null;

  const webImages = () => {
    return (
      <Row className="g-2 web-carousel">
        {pictures.map((item, index) => {
          return (
            <Col key={generateKey(`business_image_${index}`)}>
              <figure
                className="business-image"
                style={{
                  backgroundImage: `url(${getUploadUrl(
                    item.picture.data.attributes.url
                  )})`,
                }}
              />
              <span className="image-caption">{item.caption}</span>
            </Col>
          );
        })}
      </Row>
    );
  };

  const mobileImages = () => {
    return (
      <div className="mobile-carousel">
        <Carousel interval={null} controls={false}>
          {pictures.map((item, index) => {
            return (
              <Carousel.Item key={generateKey(`business_image_${index}`)}>
                <figure
                  className="business-image"
                  style={{
                    backgroundImage: `url(${getUploadUrl(
                      item.picture.data.attributes.url
                    )})`,
                  }}
                />
                <span className="image-caption">{item.caption}</span>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    );
  };

  return (
    <div className="business-image-container">
      {webImages()}
      {mobileImages()}
    </div>
  );
};

export default BusinessImages;
