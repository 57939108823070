import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { BusinessData } from '../../../types/businessRegistry';
import { generateKey } from '../../../utils/keys';

import './businessStats.scss';

const BusinessStats: React.FC = () => {
  const { i18n, instance } = useContext(GlobalContext);

  const business = instance as BusinessData;

  const {
    basedInCommunity,
    canOperateInCommunities,
    dateOfStartUp,
    legalStructure,
    neq,
    numberOfEmployees,
    owners,
    percentageOfCreeEmployees,
    seasonsOfOperation,
  } = business.attributes;

  return (
    <div className="business-stats">
      <div className="business-stats-table left">
        {percentageOfCreeEmployees && (
          <div className="stats-item">
            <div className="stats-item-label left">
              <span>
                {i18n?.t('business_page.percentage_of_cree_employees')}
              </span>
            </div>
            <div className="stats-item-text">
              <span>{percentageOfCreeEmployees}%</span>
            </div>
          </div>
        )}
        {basedInCommunity.data && (
          <div className="stats-item">
            <div className="stats-item-label left">
              <span>{i18n?.t('business_page.based_in')}</span>
            </div>
            <div>
              <div className="stats-item-chip">
                {basedInCommunity.data.attributes.name}
              </div>
            </div>
          </div>
        )}
        {canOperateInCommunities.data.length > 0 && (
          <div className="stats-item">
            <div className="stats-item-label left">
              <span>{i18n?.t('business_page.can_operate_in')}</span>
            </div>
            <div className="stats-item-chips-container">
              {canOperateInCommunities.data.map((community, index) => (
                <div
                  className="stats-item-chip"
                  key={generateKey(`can_operate_in_${index}`)}
                >
                  {community.attributes.name}
                </div>
              ))}
            </div>
          </div>
        )}
        {seasonsOfOperation.data.length > 0 && (
          <div className="stats-item">
            <div className="stats-item-label left">
              <span>{i18n?.t('business_page.seasons_of_operation')}</span>
            </div>
            <div className="stats-item-chips-container">
              {seasonsOfOperation.data.length >= 4 && (
                <div className="stats-item-chip">
                  {i18n?.t('business_page.all_seasons')}
                </div>
              )}
              {seasonsOfOperation.data.length < 4 &&
                seasonsOfOperation.data.map((season, index) => (
                  <div
                    className="stats-item-chip"
                    key={generateKey(`season_of_operation_${index}`)}
                  >
                    {season.attributes.name}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="mobile-separator" />

      <div className="business-stats-table right">
        {neq && (
          <div className="stats-item">
            <div className="stats-item-label right">
              <span>{i18n?.t('business_page.neq')}</span>
            </div>
            <div className="stats-item-text">
              <span>{neq}</span>
            </div>
          </div>
        )}
        {legalStructure.data && (
          <div className="stats-item">
            <div className="stats-item-label right">
              <span>{i18n?.t('business_page.legal_structure')}</span>
            </div>
            <div className="stats-item-text">
              <span>{legalStructure.data.attributes.name}</span>
            </div>
          </div>
        )}
        {dateOfStartUp && (
          <div className="stats-item">
            <div className="stats-item-label right">
              <span>{i18n?.t('business_page.date_of_start_up')}</span>
            </div>
            <div className="stats-item-text">
              <span>{dateOfStartUp}</span>
            </div>
          </div>
        )}

        {numberOfEmployees && (
          <div className="stats-item">
            <div className="stats-item-label right">
              <span>{i18n?.t('business_page.number_of_employees')}</span>
            </div>
            <div className="stats-item-text">
              <span>{numberOfEmployees}</span>
            </div>
          </div>
        )}
      </div>

      {owners.length > 0 && (
        <div className="business-stats-table">
          <div className="stats-item owner-item">
            <div className="stats-item-label owner">
              <span>{i18n?.t('business_page.owners')}</span>
            </div>
            <div className="stats-item-owner">
              {owners.map((owner, index) => (
                <div key={generateKey(`owner_${index}`)}>
                  <div>{owner.name}</div>
                  {owner.email && <div>{owner.email}</div>}
                  {owner.phone && <div>{owner.phone}</div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessStats;
